import LayoutAuthorized from '@/layouts/LayoutAuthorized.vue'

import {
  createRouter,
  createWebHistory,
} from 'vue-router'

export enum AUTHENTICATION {
  GUEST = 'guest',
  USER = 'user'
}

export enum ROUTES {
  AI_FINDER_REQUEST = 'ai-finder-request',
  LOGIN = 'login',
  LOGIN_GOOGLE = 'login-google',
  REGISTER = 'register',
  PASSWORD_RESET = 'password-reset',
  PASSWORD_RESET_REQUEST = 'password-reset-request',
  REPORT = 'report',
  REPORT_LIST = 'report-list',
  REPORT_LIST_FULL = 'report-list-full',
  REPORT_LIST_ASIN = 'report-list-asin',
  REPORT_LIST_UNRESTRICTED = 'report-list-unrestricted',
  REPORT_PRODUCT = 'report-product',
  SINGLE_ASIN_REQUEST = 'single-asin-request',
  UNRESTRICTED_SOURCING_REQUEST = 'unrestricted-sourcing',
  TRENDING_PRODUCTS = 'trending-products',
  PRICING = 'pricing',
  SUPPORT = 'support',
  SETTINGS = 'settings',
  VERIFY_EMAIL = 'verify-email',
  HTTP_404 = '404'
}

declare module 'vue-router' { interface RouteMeta { authentication?: `${AUTHENTICATION}` } }

export default createRouter({
  history: createWebHistory(),
  routes: [{
    path: '/',
    meta: { authentication: AUTHENTICATION.USER },
    component: LayoutAuthorized,
    redirect: { name: ROUTES.UNRESTRICTED_SOURCING_REQUEST },
    children: [{
      name: ROUTES.REPORT_LIST,
      path: '/reports',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageReportList.vue'),
      redirect: { name: ROUTES.REPORT_LIST_UNRESTRICTED },
      children: [
        {
          name: ROUTES.REPORT_LIST_FULL,
          meta: { authentication: AUTHENTICATION.USER },
          path: 'ai-profit-finder',
          component: () => import('@/pages/PageReportFullList.vue'),
        },
        {
          name: ROUTES.REPORT_LIST_ASIN,
          meta: { authentication: AUTHENTICATION.USER },
          path: 'asin',
          component: () => import('@/pages/PageReportAsinList.vue'),
        },
        {
          name: ROUTES.REPORT_LIST_UNRESTRICTED,
          meta: { authentication: AUTHENTICATION.USER },
          path: 'unrestricted',
          component: () => import('@/pages/PageReportUnrestrictedList.vue'),
        },
      ],
    },
    {
      name: ROUTES.AI_FINDER_REQUEST,
      path: '/ai-finder',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageAiProfitFinderRequest.vue'),
    }, {
      name: ROUTES.REPORT,
      path: '/report/:uuid',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageReport.vue'),
    }, {
      name: ROUTES.SINGLE_ASIN_REQUEST,
      path: '/sales-roi-analyzer',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageSingleAsinRequest.vue'),
    }, {
      name: ROUTES.UNRESTRICTED_SOURCING_REQUEST,
      path: '/unrestricted-product-sourcing',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageUnrestrictedRequest.vue'),
    }, {
      name: ROUTES.TRENDING_PRODUCTS,
      path: '/trending-products',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageTrendingProducts.vue'),
    }, {
      name: ROUTES.PRICING,
      path: '/pricing',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PagePricing.vue'),
    }, {
      name: ROUTES.SUPPORT,
      path: '/support',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageSupport.vue'),
    }, {
      name: ROUTES.SETTINGS,
      path: '/settings',
      meta: { authentication: AUTHENTICATION.USER },
      component: () => import('@/pages/PageSettings.vue'),
    }],
  }, {
    name: ROUTES.LOGIN,
    path: '/login',
    meta: { authentication: AUTHENTICATION.GUEST },
    component: () => import('@/pages/PageLogin.vue'),
  }, {
    name: ROUTES.LOGIN_GOOGLE,
    path: '/google/login/callback/',
    meta: { authentication: AUTHENTICATION.GUEST },
    component: () => import('@/pages/PageLoginGoogle.vue'),
  }, {
    name: ROUTES.REGISTER,
    path: '/register',
    meta: { authentication: AUTHENTICATION.GUEST },
    component: () => import('@/pages/PageRegister.vue'),
  }, {
    name: ROUTES.PASSWORD_RESET,
    path: '/reset_password/',
    meta: { authentication: AUTHENTICATION.GUEST },
    component: () => import('@/pages/PagePasswordReset.vue'),
  }, {
    name: ROUTES.PASSWORD_RESET_REQUEST,
    path: '/password-reset-request',
    meta: { authentication: AUTHENTICATION.GUEST },
    component: () => import('@/pages/PagePasswordResetRequest.vue'),
  }, {
    name: ROUTES.REPORT_PRODUCT,
    path: '/report/:uuid/product/:id',
    component: () => import('@/pages/PageReportProduct.vue'),
  }, {
    name: ROUTES.VERIFY_EMAIL,
    path: '/verify_email',
    meta: { authentication: AUTHENTICATION.GUEST },
    component: () => import('@/pages/PageVerifyEmail.vue'),
  }, {
    path: '/verify_email/',
    meta: { authentication: AUTHENTICATION.GUEST },
    redirect: { name: ROUTES.VERIFY_EMAIL },
  }, {
    name: ROUTES.HTTP_404,
    path: '/404',
    component: () => import('@/pages/Page404.vue'),
  }, {
    name: 'unknown-page',
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  }],
})
